import { FC } from "react";
import { Navigate } from "react-router-dom";
import { Dashboard } from "../../pages";
import { getAccessToken } from "../../utils/tokenStorage";

const PrivateRoute: FC = (): JSX.Element => {
  const token = getAccessToken();
  return token ? <Dashboard /> : <Navigate to="/signIn" />;
};

export default PrivateRoute;
