import clsx from "clsx";
import { FC } from "react";
import styles from "./styles.module.scss";

interface Props {
  type: "problem" | "user";
}

const ReportType: FC<Props> = ({ type }): JSX.Element => {
  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.problemWrapper]: type === "problem",
        [styles.userWrapper]: type === "user",
      })}
    >
      <span>{type === "user" ? "User complaint" : "Problem report"}</span>
    </div>
  );
};

export default ReportType;
