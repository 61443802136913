import { FC, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { Sidebar } from "../../components";
import styles from "./styles.module.scss";

const Dashboard: FC = () => {
  const navigate = useNavigate();
  // useEffect(() => {
  //   navigate("/statistics");
  // }, []);
  return (
    <div className={styles.wrapper}>
      <Sidebar />
      <div className={styles.contentContainer}>
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
