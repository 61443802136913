import { FC } from "react";
import styles from "./styles.module.scss";
import clsx from "clsx";

interface Props {
  isCheked: boolean;
  setIsCheked: (isCheked: boolean) => void;
}

const Radio: FC<Props> = ({ isCheked, setIsCheked }): JSX.Element => {
  return (
    <div
      onClick={() => setIsCheked(!isCheked)}
      className={clsx(styles.wrapper, { [styles.checkedWrapper]: isCheked })}
    >
      {isCheked && <div className={styles.checkedIndicator} />}
    </div>
  );
};

export default Radio;
