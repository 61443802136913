import { FC, ReactNode, useState } from "react";
import { ArrowDownIcon, ArrowSmallDownIcon } from "../../assets";
import styles from "./styles.module.scss";
import clsx from "clsx";

interface Props {
  icon: ReactNode;
  title: string;
  children?: ReactNode;
}

const SidebarDropdown: FC<Props> = ({ icon, title, children }): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div className={styles.wrapper}>
      <div
        onClick={() => setIsOpen((prev) => !prev)}
        className={clsx(styles.btn, { [styles.activeBtn]: isOpen })}
      >
        {icon}
        <p>{title}</p>
        <ArrowSmallDownIcon className={styles.arrow} />
      </div>
      {isOpen && children}
    </div>
  );
};

export default SidebarDropdown;
