import { CheckIcon } from "../../assets";
import clsx from "clsx";
import React, { FC } from "react";
import styles from "./styles.module.scss";

const Checkbox = ({ isChecked, setIsChecked, iconSize = 12 }) => {
  return (
    <div
      onClick={() => setIsChecked(!isChecked)}
      className={clsx({
        [styles.checked]: isChecked,
        [styles.unChecked]: !isChecked,
      })}
    >
      {isChecked && <CheckIcon width={iconSize} height={iconSize} />}
    </div>
  );
};

export default Checkbox;
